.App
{
    text-align: center;
    background-color: black;
}

@font-face
{
    font-family: 'Technical';
    src:
        url('../CamingoMono-SemiBold.woff2') format('woff2'),
        url('../CamingoMono-SemiBold.woff') format('woff'),
        url('../CamingoMono-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

.nav-bar
{
    display: flex;
    position: relative;
    width: 100vw;
    height: 8vh;
    background-color: #4E4C4C;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.nav-link-container
{
    gap: 4vh;
    display: flex;
    position: absolute;
    left: 4vh;
    height: 4vh;
    justify-content: center;
    align-items: center;
}

.nav-button-container
{
    display: flex;
    position: absolute;
    right: 4vh;
}

@media (max-width: 1200px)
{
    .nav-bar
    {
        font-size: 22px;
    }

}

@media (max-width: 1024px)
{
    .nav-bar
    {
        font-size: 22px;
    }
}

@media (max-width: 768px)
{
    .nav-bar
    {
        font-size: 18px;
    }

    .nav-link-container
    {
        position: unset;
    }

    .nav-button-container
    {
        position: unset;
        gap: 4vh !important;
    }

    .page-content
    {
        width: 62vw !important;
    }
}

@media (max-width: 480px)
{
    .nav-bar
    {
        flex-direction: column;
        height: 14vh;
        gap: 1vh;
        font-size: 18px;
    }

    .nav-link-container
    {
        position: unset;
    }

    .nav-button-container
    {
        position: unset;
        gap: 4vh !important;
    }

    .page-content
    {
        width: 62vw !important;
    }
}

@media (max-width: 320px)
{
    .nav-bar
    {
        flex-direction: column;
        height: 14vh;
        gap: 1vh;
        font-size: 14px;
    }

    .nav-link-container
    {
        position: unset;
    }

    .nav-button-container
    {
        position: unset;
        gap: 4vh !important;
    }

    .page-content
    {
        width: 62vw !important;
    }
}