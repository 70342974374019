.project-title
{
    display: flex;
    color: white;
    width: 100%;
    height: fit-content;
    font-family: Technical;
    justify-content: center;
    align-items: center;
}

.project
{
    gap: 2vh;
    display: flex;
    flex-direction: column;

}

.project-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0s;
    cursor: pointer;
    z-index: 1;
    background-color: #303030;
    box-sizing: border-box;
}

.project-image
{
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    border: 2px solid black;
}

.selected-pill
{
    background-color: #FFCD29;
}

.minipill
{
    font-size: 12px;
    height: fit-content;
    width: fit-content;
    padding: .5vh 1vh !important;
}

.project-pill-container
{
    display: flex;
    width: 100%;
    gap: 1vw;
    position: absolute;
    box-sizing: border-box;
    padding: 2vh 1vh;
    bottom: 0;
    flex-wrap: wrap;
}

.project-text
{
    display: none;
}

.project-container-selected
{
    display: flex;
    width: 80vw;
    height: 80vh;
    max-height: 90vh;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border: none;
    transition: 0.1s;
    flex-direction: column;
    justify-content: start;
    z-index: 100;
}

.project-container-selected .project-title
{
    width: 100%;
    height: 100%;
}

.project-container-selected .project-image
{
    /* display: none; */

    filter: opacity(.5);
    max-width: 30%;
    max-height: 100%;
    object-fit: cover;
    border: none;
}

.project-container-selected .project-text
{
    display: flex;
    /* position: absolute; */
    width: 80%;
    height: 60%;
    text-align: left;
    color: white;
    font-family: Technical;
    line-height: 3;
    overflow-y: scroll;
}

.selected-header
{
    display: none;
}

.project-container-selected .selected-header
{
    background-color: #4E4C4C;
    color: #FFCD29;
    font-family: Technical;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    width: 100%;
    height: 10%;
    padding: 2vh;
}

.project-container-selected .project-text-and-image
{
    height: 79%;
    border-radius: 0;
    justify-content: center;
    align-items: center;
    padding: 2vh 2vh;
}

.project-container-selected .project-pill-container
{
    position: unset;
    padding: 1vh;
}

.navpill
{
    background-color: #FFCD29;
}

.project-text-and-image
{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: start;
    gap: 2vh;
    border-radius: inherit;
}

.project-nav-pill-container
{
    position: relative;
    right: 0;
    display: none;
    gap: 1vw;
    width: 80%;
    justify-content: end;
    align-items: center;
}

.project-container-selected .project-nav-pill-container
{
    z-index: 99;
    display: flex;
}

::-webkit-scrollbar
{
    width: .5vh;
    border-radius: .5vh;
    background-color: black;
}

.project-text::-webkit-scrollbar
{
    width: .5vh;
    border-radius: .5vh;
    background-color: #303030;
}

::-webkit-scrollbar-thumb
{
    border-radius: .5vh;
    background-color: #FFCD29;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.project-container-selected .width-maintainer
{
    /* width: 100%; */
    display: none;
}

.project-container-selected .wide-image
{
    flex-direction: column;
}

.tall-image
{
    flex-direction: row;
}

.project-container-selected .wide-image .project-image
{
    height: 40%;
    width: 85%;
    display: flex;
    object-fit: cover;
    justify-content: center;
    align-items: center;
    object-position: 50% 50%;
}

.project-container-selected .tall-image .project-text
{
    height: 90%;
}

.project-container-selected .tall-image
{
    align-items: start;
}

@media (max-width: 1200px)
{}

@media (max-width: 1024px)
{}

@media (max-width: 768px)
{
    .project
    {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    .project-container
    {
        width: 100%;
        height: auto;
    }

    .project-container .project-image
    {
        aspect-ratio: 1/1;
    }

    .minipill
    {
        font-size: 16px;
    }

    .project-pill-container
    {
        gap: 2vw;
    }

    .project-container-selected .selected-header
    {
        font-size: 15px;
        padding: 1vh;
    }

    .project-container-selected .project-text-and-image
    {
        padding: 1vh;
    }

    .project-container-selected .project-text
    {
        width: 100%;
        font-size: 12px;
        padding: 2vh;
    }

    .project-container-selected
    {
        width: 90%;
    }

    .tall-image
    {
        flex-direction: column;
    }

    .tall-image .project-image
    {
        height: 30%;
        object-fit: cover;
    }
}

@media (max-width: 480px)
{
    .project
    {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        height: auto;
    }

    .project-container
    {
        width: 100%;
        height: auto;
    }

    .project-container .project-image
    {
        aspect-ratio: 1/1;
    }

    .minipill
    {
        font-size: 12px;
    }

    .project-pill-container
    {
        gap: 2vw;
    }

    .project-container-selected .selected-header
    {
        font-size: 15px;
        padding: 1vh;
    }

    .project-container-selected .project-text-and-image
    {
        padding: 1vh;
    }

    .project-container-selected .project-text
    {
        width: 100%;
        font-size: 12px;
        padding: 2vh;
    }

    .project-container-selected
    {
        width: 90%;
    }

    .tall-image
    {
        flex-direction: column;
    }

    .tall-image .project-image
    {
        height: 30%;
        object-fit: cover;
    }
}

@media (max-width: 320px)
{
    .project
    {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        height: auto;
    }

    .project-container
    {
        width: 100%;
        height: auto;
    }

    .project-container .project-image
    {
        aspect-ratio: 1/1;
    }

    .minipill
    {
        font-size: 10px;
    }

    .project-pill-container
    {
        gap: 2vw;
    }

    .project-container-selected .selected-header
    {
        font-size: 15px;
        padding: 1vh;
    }

    .project-container-selected .project-text-and-image
    {
        padding: 1vh;
    }

    .project-container-selected .project-text
    {
        width: 100%;
        font-size: 12px;
        padding: 2vh;
    }

    .project-container-selected
    {
        width: 90%;
    }

    .tall-image
    {
        flex-direction: column;
    }

    .tall-image .project-image
    {
        height: 30%;
        object-fit: cover;
    }
}