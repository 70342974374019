.bread-crumbs
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    right: 6vh;
    top: 12vh;
    color: white;
    font-family: Technical;
    gap: 2vh;
    width: 8vw;
    z-index: 99;
}

.bread-crumb
{
    cursor: pointer;
    transition: .2s;
}

.bread-crumb:hover
{
    margin-right: 2vh;
}

.dot-crumb
{
    height: 5px;
    width: 5px;
    background-color: white;
}

.current-crumb
{
    color: #FFCD29;
}

@media (max-width: 1200px)
{}

@media (max-width: 1024px)
{}

@media (max-width: 768px)
{
    .bread-crumbs
    {
        font-size: 16px;
        right: 2vh;
        top: 0;
        height: 100vh;
    }
}

@media (max-width: 480px)
{
    .bread-crumbs
    {
        font-size: 12px;
        right: 2vh;
        top: 0;
        height: 100vh;
    }
}

@media (max-width: 320px)
{
    .bread-crumbs
    {
        font-size: 12px;
        right: 1vh;
        top: 0;
        height: 100vh;
    }
}