.my-toast-container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 3vh;
    top: 0;
    z-index: 99;
    margin-top: 2vh;
}

.failed-toast
{
    background-color: #A51207 !important;
    border-radius: inherit;
}