.stack-pill-container-holder
{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 68vw;
    height: fit-content;
    margin-top: 8vh;
    margin-bottom: 8vh;

}

.stack-pill-container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 39%;
    height: fit-content;
}

.pill-container-header
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6vh;
    width: 100%;
    color: white;
    font-family: Technical;
    background-color: #757575;
    margin-bottom: 4vh;
}

.stack-pill-holder
{
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    height: fit-content;
    flex-wrap: wrap;
    gap: 1.5vw;
}

.project-grid
{
    margin-top: 8vh;
    display: flex;
    width: 68vw;
    flex-wrap: wrap;
    height: fit-content;
    column-gap: 2vw;
    justify-content: space-between;
    align-items: center;
    grid-row-gap: 4vh;
}

.project-container
{
    background-color: #757575;
    display: flex;
    height: 25vh;
    width: 28vh;
    border-radius: 2.5vh;
}

@media (max-width: 1200px)
{}

@media (max-width: 1024px)
{}

@media (max-width: 768px)
{
    .stack-pill-container-holder
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6vh;
        width: 100%;
    }

    .stack-pill-container
    {
        width: 100%;
    }

    .stack-pill-holder
    {
        gap: 3vw;
    }

    .project-grid
    {
        width: 100%;
    }
}

@media (max-width: 480px)
{
    .stack-pill-container-holder
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6vh;
        width: 100%;
    }

    .stack-pill-container
    {
        width: 100%;
    }

    .stack-pill-holder
    {
        gap: 3vw;
    }

    .project-grid
    {
        width: 100%;
    }
}

@media (max-width: 320px)
{
    .stack-pill-container-holder
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6vh;
        width: 100%;
    }

    .stack-pill-container
    {
        width: 100%;
    }

    .stack-pill-holder
    {
        gap: 3vw;
    }

    .project-grid
    {
        width: 100%;
    }

}