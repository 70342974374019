.footer-links
{
    display: flex;
    gap: 2vh;
    justify-content: center;
    align-items: center;
    height: 2vh;
}

.footer
{
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    background-color: black;
    gap: 2vh;
    position: relative;
}

.footer-text
{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: Technical;
}

.footer-button-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6vh;
}

@media (max-width: 1200px)
{}

@media (max-width: 1024px)
{}

@media (max-width: 768px)
{
    .footer
    {
        padding-top: 12vh;
    }
}

@media (max-width: 480px)
{
    .footer
    {
        padding-top: 14vh;
    }
}

@media (max-width: 320px)
{
    .footer
    {
        font-size: 12px;
    }

    .footer-links
    {
        gap: 1vh;
    }
}