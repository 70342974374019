.page-background
{
    width: 100vw;
    display: flex;
    background-color: black;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4vh 0vh;
    max-width: 100%;
}

.page-title
{
    font-family: Technical;
    color: white;
    font-size: 50px;
}

.page-small-text
{
    font-family: Technical;
    color: white;
    font-size: 20px;
}

.divider
{
    height: 0px;
    border-bottom: 2px solid white;
}

.page-content
{
    scroll-margin-top: 3vh;
    display: flex;
    height: fit-content;
    width: fit-content;
    background-color: black;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2vh;
}

.pill
{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    font-family: Technical;
    border-radius: 2vh;
    padding: 1.5vh;
}

.pill-container
{
    margin-top: 2vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pillClickable
{
    cursor: pointer;

}

@media (max-width: 1200px)
{}

@media (max-width: 1024px)
{}

@media (max-width: 768px)
{
    .page-title
    {
        font-size: 24px;
    }

    .page-small-text
    {
        font-size: 18px;
    }

    .pill
    {
        font-size: 16px;
        padding: 1vh;
    }

    .pill-container
    {
        flex-direction: column;
        gap: 3vh;
        width: 35vw;
    }

    .pill-container .pill
    {
        width: 100%;
    }
}

@media (max-width: 480px)
{
    .page-title
    {
        font-size: 24px;
    }

    .page-small-text
    {
        font-size: 18px;
    }

    .pill
    {
        font-size: 16px;
        padding: 1vh;
    }

    .pill-container
    {
        flex-direction: column;
        gap: 3vh;
        width: 35vw;
    }

    .pill-container .pill
    {
        width: 100%;
    }
}

@media (max-width: 320px)
{
    .page-title
    {
        font-size: 16px;
    }

    .page-small-text
    {
        font-size: 12px;
    }

    .pill
    {
        font-size: 12px;
        padding: 1vh;
    }

    .pill-container
    {
        flex-direction: column;
        gap: 3vh;
        width: 35vw;
    }

    .pill-container .pill
    {
        width: 100%;
    }
}