.nav-link
{
    font-family: Technical;
    cursor: pointer;
    color: white !important;
    transition: 0.2s !important;
    ;
}

.bounce:hover
{
    margin-bottom: 1vh;
}

.nav-button
{
    display: flex;
    height: 5vh;
    cursor: pointer;
}

.nav-button-container
{
    display: flex;
    gap: 2vh;
}

.nav-button-img
{
    display: flex;
    height: 5vh;
    width: auto;
}