.about-me-container
{
    display: flex;
    align-items: flex-start;
    width: 50vw;
    height: fit-content;
    gap: 4vh;
    line-height: 2;
    margin-top: 14vh;
}

.about-me-text
{
    color: white;
    font-family: Technical;
    width: 80%;
    text-align: left;
}

.about-me-img
{
    height: auto;
    width: 20%;
}

#about-me-anchor
{
    margin-top: 32vh;
    margin-bottom: 32vh;
}

@media (max-width: 1200px)
{
    .about-me-text
    {
        font-size: 20px;
    }

    .about-me-container
    {
        width: 72vw;
        margin-top: 18vh;
    }
}

@media (max-width: 1024px)
{
    .about-me-text
    {
        font-size: 18px;
    }

    .about-me-container
    {
        width: 72vw;
        margin-top: 18vh;
    }
}

@media (max-width: 768px)
{
    .about-me-container
    {
        flex-direction: column;
        width: 100%;
        margin-top: 6vh;
    }

    .about-me-text
    {
        width: 100%;
        font-size: 18px;
        padding-top: 8vh;
    }

    .about-me-img
    {
        display: none;
    }

    #about-me-anchor
    {
        margin-top: 7vh;
        margin-bottom: 9vh;
    }
}

@media (max-width: 480px)
{
    .about-me-container
    {
        flex-direction: column;
        width: 100%;
        margin-top: 6vh;
    }

    .about-me-text
    {
        width: 100%;
        font-size: 14px;
        padding-top: 6vh;
    }

    .about-me-img
    {
        display: none;
    }

    #about-me-anchor
    {
        margin-top: 7vh;
        margin-bottom: 9vh;
    }
}

@media (max-width: 320px)
{
    .about-me-container
    {
        flex-direction: column;
        width: 100%;
        margin-top: 6vh;
    }

    .about-me-text
    {
        width: 100%;
        font-size: 12px;
    }

    .about-me-img
    {
        display: none;
    }

    #about-me-anchor
    {
        margin-top: 7vh;
        margin-bottom: 9vh;
    }
}