.message-field
{
    color: white;
    border-color: gray;
    border-radius: 0;
    height: 40vh;
    width: 75%;
    background-color: black;
    border-style: solid;
    padding: 10px 0px 10px 9px;
}

.email-field
{
    color: white;
    border-color: gray;
    border-radius: 0;
    height: 5vh;
    width: 75%;
    background-color: black;
    border-style: solid;
    padding: 10px 0px 10px 9px;
}

.name-field
{
    color: white;
    border-color: gray;
    border-radius: 0;
    height: 5vh;
    width: 75%;
    background-color: black;
    border-style: solid;
    padding: 10px 0px 10px 9px;
}

::placeholder
{
    font-family: Technical;
    color: white;
}

.contact-form
{
    color: white;
    font-family: Technical;
    width: 68vw;
    height: fit-content;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
    gap: 4vh;
    margin-bottom: 8vh;
}

.name-field,
.email-field,
.message-field
{
    width: 95%;
}

.contact-form .pill
{
    font-size: 14px;
    padding: 1vh 2vh;
}